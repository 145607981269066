import React from "react";
import {
  Box,
  Flex,
  Heading,
  // Input,
  Text,
  WrapItem,
  Avatar,
  useDisclosure,
} from "@chakra-ui/react";
// imported icons from react-icon

import { IoIosArrowDown } from "react-icons/io";

// chakra ui icons
// import { CopyIcon } from "@chakra-ui/icons";
// pop-up model of currencies
import Modelview from "../Modal/Modal";
function ModelHelper({ handleinput, setToken, token, input, connected }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    // {/* second head contains avatar name and arrow */}
    <Box>
      {connected && (
        <Modelview isOpen={isOpen} onClose={onClose} setToken={setToken} />
      )}
      <Flex w={"100%"} cursor="pointer" onClick={onOpen}>
        <WrapItem>
          <Avatar
            mr={2}
            size="xs"
            name="Cake"
            src="https://tokens.pancakeswap.finance/images/0xBc7d6B50616989655AfD682fb42743507003056D.png"
          />{" "}
        </WrapItem>
        <Heading as="h6" size="sm" color="#280d5f">
          {token?.name}
        </Heading>
        <Flex align="center" ml={1}>
          <IoIosArrowDown />
          {/* <CopyIcon ml={2} /> */}
        </Flex>
        <Text
          marginLeft={"auto"}
          marginRight={2}
          fontSize="12px"
          mr="10px"
          fontWeight={400}
          color="#989696"
        >
          Balance:
          <Text as="span" color={"black"}>
            {token?.balance}
          </Text>{" "}
          {/* Balance:{token?.balance} */}
        </Text>
      </Flex>
      {/* <Box>
        <Flex w={"100%"} cursor="pointer" onClick={onOpen}>
          <WrapItem>
            <Avatar
              mr={2}
              size="xs"
              name="Cake"
              src="https://t1.gstatic.com/images?q=tbn:ANd9GcShqSRZLMKcbMguH0bIVS5l3BpyZ9_OuFVMt19zfjLFN1is5md-"
            />{" "}
          </WrapItem>
          <Heading as="h6" size="sm" color="#280d5f">
            {token?.name}
          </Heading>
          <Flex align="center" ml={1}>
            <IoIosArrowDown />
            <CopyIcon ml={2} />
          </Flex>
          <Text
            marginLeft={"auto"}
            marginRight={2}
            fontSize="sm"
            fontWeight={400}
            color="#280d5f"
          >
            Balance:{token?.balance}
          </Text>{" "}
        </Flex>
      </Box>
      {/* input box  */}
      {/* <Box
        bg="#eeeaf4"
        h={"80px"}
        borderRadius={"20px"}
        mt={2}
        textAlign={"right"}
      > */}
      {/* <Input
          variant="unstyled"
          placeholder="0.0"
          p={3}
          dir="rtl"
          onChange={handleinput ? handleinput : null}
          color="#280d5f"
          fontWeight={500}
          fontSize="md"
          value={input ? input : input}
        ></Input> */}
      {/* <Text mr={3} fontSize="xs" fontWeight={600} color="#966eaa"> */}
      {/* {input ? "~" : ""} */}
      {/* {input} {input ? "USD" : ""} */}
      {/* </Text> */}
      {/* </Box> */}
    </Box>
  );
}

export default ModelHelper;
