import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  // Swap,
  // LimitHome,
  // Liquidity,
  Navbar,
  // Home,
  // NFTList,
  // BuyNFT,
  // Create,
  // ListForSale,
} from "./Components/index,.js";
import { Box } from "@chakra-ui/react";
import Swap from "./Components/Swap/Swap.js";
function Hero() {
  return (
    <Box
      minH="90vh"
      p={{ base: 3, sm: 4, md: "8" }}
      borderRadius="56px"
      bg="#F4F4F4DE"
    >
      <Router>
        <Navbar />
        <div>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/swap" element={<Swap />} />
          </Routes>
        </div>
      </Router>
    </Box>
  );
}

export default Hero;
