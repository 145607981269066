import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
  Divider,
  Heading,
  Flex,
  Text,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { tokensList } from "../../artifacts/tokensList/tokensList";
import { AAbi } from "../../artifacts/A_ABI.js";
// import { pancakeSwapAbi } from "../../artifacts/PancakeSwapABi/pancakeSwapAbi.js";
const ModalView = ({ isOpen, onOpen, onClose, setToken }) => {
  const [tokenListNew, setTokenList] = useState([]);
  // console.log(pancakeSwapAbi);
  const getPriceOfEachToken = async () => {
    const Web3 = require("web3");
    // console.log("calling from Model");

    const provider = window.ethereum;
    const data = provider
      .request({ method: "eth_requestAccounts" })

      .then((accounts) => {
        let account = accounts[0]; // Set up input parameters
        const web3 = new Web3(provider);
        const balance = tokensList.map(async (token) => {
          const tokenBContract = new web3.eth.Contract(AAbi, token.address);
          const balance = await tokenBContract.methods
            .balanceOf(account)
            .call();
          return {
            balance: balance.toString().slice(0, 5),
            address: token.address,
            name: token.name,
          };
        });
        // Promise.all(balance).then((data) => setTokenList(data));
        const newArr = Promise.all(balance).then((data) => {
          setTokenList(data);
          return data;
        });
        return newArr;
      });
    return data;
  };
  useEffect(() => {
    getPriceOfEachToken();
  }, []);

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        // scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          mt={-1}
          w={{ base: "350px", md: "sm", lg: "lg" }}
          borderRadius="40px"
          h={" 100vh"}
          position="fixed"
          overflow={"hidden"}
        >
          <ModalHeader>
            <Heading as="h4" fontSize={"lg"} fontWeight={700} mt={3} mb={5}>
              Select a Token{" "}
            </Heading>{" "}
            <Divider />
            <Input
              mt={5}
              bg="#eeeaf4"
              fontWeight={600}
              color={"#7a6eaa"}
              placeholder="Search name or paste address"
              size="lg"
              borderRadius={"16px"}
            />{" "}
            <Text fontSize={"12px"} fontWeight="600" mt={4} mb={2}>
              Common Tokens{" "}
            </Text>{" "}
          </ModalHeader>{" "}
          <ModalCloseButton color={"#31d3c0"} mt={3} mr={3} />{" "}
          <ModalBody
            mt={-5}
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "11px",
              },
              "&::-webkit-scrollbar-track": {
                width: "8px",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#7a6eaa",
                borderRadius: "24px",
              },
            }}
          >
            <Box>
              {" "}
              {tokenListNew.map((item) => {
                return (
                  <Flex onClick={onClose} justify={"space-between"}>
                    <WrapItem
                      id={item}
                      mb={3}
                      cursor={"pointer"}
                      onClick={() => setToken(item)}
                      w={"100%"}
                    >
                      <Avatar
                        size="xs"
                        name="BNB"
                        mr={2}
                        src="https://tokens.pancakeswap.finance/images/0xBc7d6B50616989655AfD682fb42743507003056D.png"
                      />
                      <Flex direction={"column"}>
                        <Heading as="h5" size="sm" color="#280d5f">
                          {" "}
                          {item.name}{" "}
                        </Heading>{" "}
                        <Text fontSize="xs" fontWeight={600} color="#280d5f">
                          Dummy token
                        </Text>{" "}
                      </Flex>{" "}
                    </WrapItem>{" "}
                    <Text fontSize="sm" fontWeight={600} color="#280d5f">
                      {item.balance}
                    </Text>{" "}
                  </Flex>
                );
              })}{" "}
            </Box>{" "}
          </ModalBody>{" "}
          {/* <ModalFooter h="100vh">loremasfkskfnf loremasfkskfnf</ModalFooter> */}{" "}
        </ModalContent>{" "}
      </Modal>{" "}
    </>
  );
};
export default ModalView;
