import React from "react";
import { useMediaQuery } from "react-responsive";
// import { useSelector, useDispatch } from "react-redux";
import ConnectWalletButton from "./ConnectWalletButton";
import SwitchNetworkButton from "./SwitchNetworkButton";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo/proswap.png";
function Navbar() {
  // -----------React Media Queries-----------
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  //

  return (
    <Box>
      <Flex justify="space-between" align="center" h={14}>
        <Box>
          <Link to="/" display="inline">
            <Image
              src={logo}
              alt="logo"
              boxSize={{
                base: "60px",
                sm: "100px",
                md: "170px",
              }}
            />
          </Link>
        </Box>
        <Box>
          <Flex justify="space-between" align="center">
            <Box
              ml={3}
              // display={{ base: "none", sm: "none", md: "none", lg: "inline" }}
              display={isTabletOrMobile ? "none" : "inline"}
            >
              <Heading
                as="h8"
                size="xs"
                fontSize="15px"
                fontWeight={700}
                ml={10}
                color="#7645d9"
              >
                <Link to="/swap">Swap</Link>
              </Heading>

              <Heading
                as="h8"
                size="xs"
                fontSize="15px"
                fontWeight={700}
                ml={9}
                color="#8C8C8C"
              >
                {/* <Link _disabled="true" to="/liquidity">
                  Liquidity
                </Link> */}
              </Heading>
            </Box>
          </Flex>
        </Box>

        <Box align="center">
          <Box h="100%" align="center">
            <SwitchNetworkButton />

            <ConnectWalletButton />
          </Box>
        </Box>
      </Flex>
      {/* Footer Navbar for mobile Device*/}
      {isTabletOrMobile && (
        <Box
          position="fixed"
          bottom={0}
          w="100%"
          bg="#fff"
          zIndex={11}
          p={3}
          color="#7a6eaa"
          fontSize="13px"
          fontWeight={700}
        >
          <Flex justify="space-around">
            <Heading as="h7" size="xs" ml={1}>
              <Link to="/swap">Swap</Link>
            </Heading>
            <Heading as="h7" size="xs" ml={1}>
              <Link to="/liquidity">Liquidity</Link>
            </Heading>
          </Flex>
        </Box>
      )}
    </Box>
  );
}
export default Navbar;
