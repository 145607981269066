import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { BiWallet } from "react-icons/bi";

const ConnectWalletButton = () => {
  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    connectToWallet();
  }, []);

  const connectToWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else {
      console.error("No Ethereum provider found");
    }
  };

  return (
    <Button
      colorScheme="messenger"
      borderRadius="14px"
      bg="#2B88FFE0"
      w="30"
      ml="5"
      onClick={connectToWallet}
    >
      <Flex justifyContent={"space-between"} align={"center"}>
        <Text>
          {walletAddress
            ? `${walletAddress.substring(0, 6)}...${walletAddress.substring(
                walletAddress.length - 4
              )}`
            : "Connect Wallet"}
        </Text>
        <Box ml={3}>
          <BiWallet size="1.4em" />
        </Box>
      </Flex>
    </Button>
  );
};

export default ConnectWalletButton;
