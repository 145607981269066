export const tokensList = [
  {
    name: "MTK200",
    address: "0xC9941DDA52450AbcfBa729BdfbEE5348799590bB",
  },
  {
    name: "MTK201",
    address: "0xA5c3b9dc162b2cfaf8637B29fb15aB5cfCb63c02",
  },
  // {
  //   name: "MTK3",
  //   address: "0x9DD23B2AfB76046ca78D5C53E6d38611393bf10A",
  // },
  // {
  //   name: "MTK4",
  //   address: "0xb7C3A4bc5d7EeEB2AB33482b2fBEb92a456c30D8",
  // },
  // {
  //   name: "MTK5",
  //   address: "0xcBd460f6e7B2d722479237D685C92994d95324e7",
  // },
  // {
  //   name: "MTK6",
  //   address: "0x7E2a511f9f9d9C2b22eD34580c7410ba0b12389C",
  // },
  // {
  //   name: "MTK7",
  //   address: "0x441A2737A4d54cB4Ec82F5d902d6E976F02020D9",
  // },
  // {
  //   name: "MTK8",
  //   address: "0x1fB20f324Ca05622F7415dE9775a43C519D9e906",
  // },
  // {
  //   name: "MTK9",
  //   address: "0xFa36984f55ecDE92DF2853a835b61691ab86A3CB",
  // },
  // {
  //   name: "MTK10",
  //   address: "0x96084de25767c9d5bB57984D2623c0f125100Aac",
  // },
];
