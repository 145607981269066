import React, { useState, useEffect, useRef } from "react";
// import { ethers } from "ethers";
import "./swap.css";
import {
  Box,
  Text,
  Heading,
  Flex,
  Card,
  Button,
  Center,
  Image,
  Input,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import left_img from "../../assets/card_imgs/left.svg";
import right_img from "../../assets/card_imgs/right.svg";
import middle from "../../assets/card_imgs/middle_triangle.svg";
import { swapABI } from "../../artifacts/swapABI";
import { AAbi } from "../../artifacts/A_ABI";
import getTokenPrice from "../../Helpers/getTokenPrice";
// import getCurrentTokenPrice from "../../Helpers/getCurrentTokenPrice";

// getCurrentTokenPrice;
// import { Divider, Icon } from "@chakra-ui/react";
// import { FaIconName } from "react-icons/fa";
//
// import { IoIosArrowDown } from "react-icons/io";
//
// import { CopyIcon } from "@chakra-ui/icons";
import { BsArrowDownShort } from "react-icons/bs";
import { ethers } from "ethers";
import Modal from "../Modal/Modal";
import ModelHelper from "./ModelHelper";
// const Web3 = require("web3");
// const provider = window.ethereum;
// const web3 = new Web3(provider);
const pancakeSwapContractAddress = "0x1bb8e73CDaC2548068CBCe3E87118452f064790F";

let pancakeSwapContract;

function Swap() {
  const [input, setinput] = useState("");
  const [Current_value, setCurrentValue] = useState(null);
  const [tokenOne, setTokenOne] = useState(null);
  const [tokenTwo, setTokenTwo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState("");
  const input_ref = useRef();
  // const [signer, setSigner] = useState("");
  // window.addEventListener("load", async () => {
  //   try {
  //   } catch (error) {}
  // });
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let signer = provider.getSigner();
  useEffect(() => {
    const func = async () => {
      await window.ethereum.request({ method: "eth_requestAccounts" });

      const address = await signer.getAddress();
      setAccount(address);
      await window.ethereum.enable();
      setConnected(true);

      const initial_token = await getTokenPrice();

      setTokenOne(initial_token[0]);
      setTokenTwo(initial_token[1]);

      // provider = new ethers.providers.Web3Provider(window.ethereum);
      // signer = provider.getSigner(account);
      pancakeSwapContract = new ethers.Contract(
        pancakeSwapContractAddress,
        swapABI,
        signer
      );
    };
    func();
    setConnected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(account);
  // useEffect(() => {
  //   if (connected) {
  //     window.location.reload();
  //     setConnected(false);
  //   }
  // }, []);
  //

  //
  const swapFunc = async () => {
    console.log("===========" + pancakeSwapContract);
    // Check if MetaMask is installed
    if (typeof window.ethereum !== "undefined") {
      // console.log("MetaMask is installed!");
    }
    setLoader(true);
    if (!Current_value) {
      setLoader("error");
    }

    // console.log(tokenOne.address, tokenTwo.address);

    // Set up swap parameters
    // console.log("Account Address ", account);
    // const fromToken = "0xfA36d297a3A4a39e85A3884C1609f98DA8589EA8"; // BNB token address
    // const toToken = "0x64cE3b2020C0E3E6FACe7C6dC81699dAFCC01b67"; // BUSD token address

    const fromAmount = ethers.utils.parseUnits(Current_value, "ether");

    const deadline = 16114012961;

    const tokenAContract = new ethers.Contract( //0x3C2dE16D77570cc6625bBD0a13eFBB071e98AaFF
      // "0xC9941DDA52450AbcfBa729BdfbEE5348799590bB",
      tokenOne.address,
      AAbi,
      signer
    );

    const tokenBContract = new ethers.Contract( //0x87c35E9244dC9cD5408E27183E918711767c13b3
      // "0xA5c3b9dc162b2cfaf8637B29fb15aB5cfCb63c02",
      tokenTwo.address,
      AAbi,
      signer
    );
    await tokenAContract.approve(pancakeSwapContractAddress, Current_value);

    await tokenBContract.approve(pancakeSwapContractAddress, Current_value);

    const path = [
      // "0xC9941DDA52450AbcfBa729BdfbEE5348799590bB",
      // "0xA5c3b9dc162b2cfaf8637B29fb15aB5cfCb63c02",
      tokenOne.address,
      tokenTwo.address,
    ];
    console.log(account);
    // Swap code
    try {
      const tx = await pancakeSwapContract.swapExactTokensForTokens(
        fromAmount,
        0,
        //min,
        path,
        account,
        deadline
      );
      console.log("Checking tx :", tx);
      const res = await tx.wait();

      if (res) {
        const balanceOne = await tokenAContract.balanceOf(account);
        let balance_one = balanceOne * Math.pow(10, -18);
        balance_one = balance_one.toString();

        const token_one = {
          balance: balance_one.substring(0, balance_one.length - 10),
          address: tokenOne.address,
          name: tokenOne.name,
        };
        setTokenOne(token_one);
        //
        const balanceTwo = await tokenBContract.balanceOf(account);
        let balance_Two = balanceTwo * Math.pow(10, -18);
        balance_Two = balance_Two.toString();
        const token_two = {
          balance: balance_Two.substring(0, balance_Two.length - 10),
          address: tokenTwo.address,
          name: tokenTwo.name,
        };
        setTokenTwo(token_two);
        setinput(0);
        setCurrentValue(0);
        input_ref.current.value = null;
        setLoader(false);

        //  balance: balance * Math.pow(10, -18),
        //     address: token.address,
        //     name: token.name,
      } else {
        setLoader("failed");
      }
    } catch (e) {
      console.log(e);
      setLoader("failed");
    }
  };

  const getValue = async (val) => {
    //   console.log("value ", value);
    setCurrentValue(val);
    if (val > 0) {
      const fromAmount = ethers.utils.parseUnits(val, "ether");

      // console.log(fromAmount.toString());
      console.log(tokenOne, tokenTwo);
      const path = [
        // "0xC9941DDA52450AbcfBa729BdfbEE5348799590bB",
        // "0xA5c3b9dc162b2cfaf8637B29fb15aB5cfCb63c02",
        tokenOne.address,
        tokenTwo.address,
      ];

      const amountOut = await pancakeSwapContract.getAmountsOut(
        fromAmount,
        path
      );
      // console.log(amountOut.toString());
      // console.log(
      //   "=======" + ethers.utils.formatEther(amountOut[1].toString())
      // );
      setinput(ethers.utils.formatEther(amountOut[1].toString()));
      //
    } else {
      // console.log("This value is blank or 0");
      setinput(0);
      return;
    }
  };
  // GetValue for new Tokens
  const get_vaue_on_swap = async (tokenA, tokenB) => {
    //   console.log("value ", value);
    // setCurrentValue(val);
    if (Current_value > 0) {
      const fromAmount = ethers.utils.parseUnits(Current_value, "ether");

      // console.log(fromAmount.toString());
      console.log(tokenOne, tokenTwo);
      const path = [
        // "0xC9941DDA52450AbcfBa729BdfbEE5348799590bB",
        // "0xA5c3b9dc162b2cfaf8637B29fb15aB5cfCb63c02",
        tokenA.address,
        tokenB.address,
      ];

      const amountOut = await pancakeSwapContract.getAmountsOut(
        fromAmount,
        path
      );
      // console.log(amountOut.toString());
      // console.log(
      //   "=======" + ethers.utils.formatEther(amountOut[1].toString())
      // );
      setinput(ethers.utils.formatEther(amountOut[1].toString()));
      //
    } else {
      // console.log("This value is blank or 0");
      setinput(0);
      return;
    }
  };
  // Take input from
  const handleinput = (e) => {
    let val = e.target.value;
    // if (val && val > 0) {

    // }
    console.log("value in handle input ", val);
    getValue(val);
    if (val > 0) {
      setLoader(false);
    }
  };
  //
  const setTokenFirst = (token) => {
    if (token.name === tokenTwo.name) {
      setTokenTwo(tokenOne);
    }
    setTokenOne(token);
  };
  //
  const setTokenSecond = (token) => {
    if (token.name === tokenOne.name) {
      setTokenOne(tokenTwo);
    }
    setTokenTwo(token);
  };
  // swap arrow handler.
  const swapToken = () => {
    setTokenOne(tokenTwo);
    setTokenTwo(tokenOne);
    get_vaue_on_swap(tokenTwo, tokenOne);
  };
  const { isOpen, onClose } = useDisclosure();
  console.log(account);
  return (
    <Box h="inherit">
      {connected && <Modal isOpen={isOpen} onClose={onClose} setToken={""} />}

      <Flex
        // align={"center"}
        justify={{ base: "center", sm: "center", md: "center", lg: "normal" }}
        alignItems={"center"}
        mt={5}
        wrap={{ base: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
      >
        <Box
          textAlign={"left"}
          // w="50%"
          mt={{ base: 4, sm: 2, md: 10, lg: 0 }}
          w={{ md: "100%", lg: "50%" }}
          lineHeight={"100%"}
          mr={30}
        >
          <Text color="#BA28BD" fontWeight={"600"}>
            Welcome to
          </Text>
          <Box color="#004AAD">
            <Heading lineHeight={"150%"}>ProSwap</Heading>
            <Heading lineHeight={"150%"}>Cryptocurrency</Heading>
            <Heading lineHeight={"150%"}>Exchange</Heading>
          </Box>
          <Text
            color="#BA28BD"
            lineHeight={"140%"}
            mt={{ base: 1, md: 2, lg: 3 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Text>
        </Box>
        <Box
          w={{ base: "100%", sm: "100%", md: "100%", lg: "40%" }}
          // ml={{ lg: 14 }}
          m="0 auto"
        >
          <Card
            bg="#DFDFED"
            // w="80%"
            w={{ base: "100%", sm: "lg", md: "md", lg: "80%" }}
            textAlign={"left"}
            pt={3}
            variant={"unstyled"}
            borderRadius="20px"
            shadow={"lg"}
            mt={{ base: 5, sm: 5, lg: 0 }}
          >
            <Box p={4}>
              <Text mb={2} fontSize={"12px"}>
                From
              </Text>
              <ModelHelper
                setTokenTwo={setTokenOne}
                setToken={setTokenFirst}
                token={tokenOne}
                // input={input}
                connected={connected}
              />

              <Flex>
                <Box className="vertical-line-container">
                  <Box className="vertical-line"></Box>
                  {/* <Icon as={IoIosArrowDown} boxSize={6} className="icon" /> */}
                  <Box className="icon">
                    <Center onClick={swapToken}>
                      <Box
                        cursor={"pointer"}
                        bg="#eeeaf4"
                        p={1}
                        borderRadius={"50%"}
                        // mt={2}
                      >
                        <BsArrowDownShort color="#46cdda" size={"1.5em"} />
                      </Box>
                    </Center>
                  </Box>
                </Box>
                <Box textAlign={"left"} ml={4}>
                  <Box mt={1}>
                    <Text fontWeight={400} fontSize={"12px"}>
                      You Send
                    </Text>
                    <Heading as="h2" size="md" noOfLines={1}>
                      {/* Taking input */}
                      <Input
                        variant="unstyled"
                        size="lg"
                        autoFocus="true"
                        fontSize="22px"
                        placeholder="0"
                        onChange={handleinput}
                        disabled={tokenOne ? false : true}
                        ref={input_ref}
                        // value={Current_value ? Current_value : null}
                      />
                    </Heading>
                  </Box>
                </Box>
              </Flex>
              <Text m={1} ml={"6px"} fontSize={"12px"}>
                To
              </Text>

              <ModelHelper
                setTokenTwo={setTokenTwo}
                setToken={setTokenSecond}
                token={tokenTwo}
                input={input}
                connected={connected}
              />
              <Box ml={10}>
                <Text fontWeight={400} fontSize={"12px"}>
                  You Received
                </Text>
                <Heading as="h2" size="md" noOfLines={1}>
                  {input ? input : 0}
                </Heading>
              </Box>
            </Box>
            {/* Swap button */}
            <Box>
              <center>
                <Button
                  onClick={swapFunc}
                  colorScheme="messenger"
                  bg="#2B88FFE0"
                  w="36"
                >
                  {loader === true && <Spinner />}
                  {loader === "failed" && <Text>Try Again !!</Text>}
                  {loader === false && <Text>Swap</Text>}
                  {/* Swap */}
                </Button>
              </center>
            </Box>
            <Flex
              mt={-20}
              justifyContent={"space-between"}
              // mb={-2}
              borderRadius="20px"
              overflow={"hidden"}
            >
              {/* <Box> */}{" "}
              <Image src={left_img} w="50px" h="170px" overflow={"hidden"} />
              {/* </Box> */}
              <Box
                position={"absolute"}
                left={"50%"}
                // top="50%"
                transform="translate(-50%, -50%)"
                bottom={"-6%"}
              >
                <Image src={middle} />
              </Box>
              <Image src={right_img} w="50px" h="170px" />
            </Flex>
          </Card>
        </Box>
      </Flex>
    </Box>
  );
}

export default Swap;
