import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  // Swap,
  // LimitHome,
  // Liquidity,
  Navbar,
  // Home,
  // NFTList,
  // BuyNFT,
  // Create,
  // ListForSale,
} from "./Components/index,.js";

import { Box } from "@chakra-ui/react";
import Hero from "./Hero";
function App() {
  return (
    <Box
      // m={6}
      // p={10}
      p={{ base: 2, sm:2, md:10 }}
      // pl={18}
      // pr={18}
      // pt={5}
      className="App"
      // borderRadius="56px"
      // bg="#F4F4F4DE"
      minH="100vh"
      h="auto"
      background="linear-gradient(316deg, rgba(204, 63, 254, 0.52) 0%, rgba(43, 136, 255, 0.88) 92.71%)"
    >
      <Hero />
    </Box>
  );
}

export default App;
