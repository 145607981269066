// import { tokensList } from "../utils/tokens.js";
import { tokensList } from "../artifacts/tokensList/tokensList.js";
// import { BAbi } from "../artifacts/tokenAbis/tokens.js";
import { AAbi } from "../artifacts/A_ABI.js";
const getTokenPrice = async () => {
  const Web3 = require("web3");
  const tokens = tokensList.slice(0, 2);
  const provider = window.ethereum;
  let initialTokenData = provider
    .request({ method: "eth_requestAccounts" })

    .then(async (accounts) => {
      let account = accounts[0]; // Set up input parameters
      const web3 = new Web3(provider);
      const balance = tokens.map(async (token) => {
        const tokenBContract = new web3.eth.Contract(AAbi, token.address);
        let balance = await tokenBContract.methods.balanceOf(account).call();
        balance = balance * Math.pow(10, -18).toString();
        const ans = Math.round(balance * 100) / 100;

        return {
          balance: ans,
          // balance: b,

          address: token.address,
          name: token.name,
        };
      });
      const newArr = Promise.all(balance).then((data) => {
        return data;
      });
      return newArr;
    });
  return initialTokenData;
};
export default getTokenPrice;
